import classNames from "classnames";
import "./text.scss";


export const TextHighlight = ({children, className}) => {
  return (
    <span className={classNames("text-highlight", className)} >
      {children}
    </span>
  );
};
