import "./bottom.scss";
import { BottomImage } from "../../resources/image";
import { Content, ContentContainer, ContentDescription, ContentTitle, ImageContainer } from "../component/sectionLayout/SectionLayout";
import { ThreeFingers } from "../../resources/emoji";
import { TextHighlight } from "../../component/text/Text";

const CLASSNAME = "bottom"

export const Bottom = () => {
  return (
    <Content className={CLASSNAME} >
      <ContentContainer className={CLASSNAME} >
        <ThreeFingers className={CLASSNAME} />
        <ContentTitle className={CLASSNAME} >
          {`Engage in a `}<TextHighlight className={CLASSNAME} >{`Thriving\nGaming Community`}</TextHighlight>
        </ContentTitle>
        <ContentDescription className={CLASSNAME} >
          Connect with a passionate community of gamers, share your experiences, participate in discussions, and make new friends who share your gaming interests. Immerse yourself in a supportive network that enhances your gaming experience.
        </ContentDescription>
      </ContentContainer>
      <ImageContainer className={CLASSNAME} >
        <BottomImage className={CLASSNAME} />
      </ImageContainer>
    </Content>
  );
};