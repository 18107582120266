import "./button.scss";
import classNames from "classnames";
import { useCallback } from "react";
import { AppStoreBadge, GoogleStoreBadge } from "../../resources/image";
import { useCheckOs } from "../../hook/useCheckOs";

export const ROUND_BUTTON = {
  SIZE: {
    XLARGE: "s-xl",
    LARGE: "s-l",
    MEDIUM: "s-m",
    SMALL: "s-s"
  },
  COLOR: {
    PRIMARY: "c-p",
    SECONDARY: "c-s",
    NEGATIVE: "c-n",
    TRANSPARENT: "c-tp"
  }
};

export const RoundButton = ({children, size, color, isDisable, className, onClick}) => {

  const onClickLocal = useCallback(() => {
    if(isDisable) {
      return;
    };
    if(onClick) {
      onClick();
    };
  }, [isDisable, onClick]);

  return (
    <span className={classNames("round-button", size, color, isDisable ? "disable" : "", className)} onClick={onClickLocal} >
      {children}
    </span>
  );
};

export const RoundButtonWithDesc = ({className, onClick}) => {
  const { isIos, isAndroid } = useCheckOs();

  return (
    <div className="button-w" >
      {
        <>
          {isIos && <AppStoreLink className={`${className}`} />}
          {isAndroid && <PlayStoreLink className={`${className}`} />}
          {!isIos && !isAndroid &&
            <RoundButton 
              className={classNames("l-button", className)}
              onClick={onClick}
            >
              Get started for FREE
            </RoundButton>
          }
        </>
      }
    </div>
  );
};


export const ProductHuntBadge = ({className}) => {
  return (
    <span className={classNames("product-hunt-badge-w", className)} >
      <a 
        href="https://www.producthunt.com/posts/minimap-game-rating-and-recommendation?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-minimap&#0045;game&#0045;rating&#0045;and&#0045;recommendation" 
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=396407&theme=light" 
          alt="Minimap&#0058;&#0032;Game&#0032;rating&#0032;and&#0032;recommendation - Gaming&#0032;made&#0032;personal | Product Hunt" 
          style={{
            width: "148px", 
            height: "32px"
          }} 
          width="148" 
          height="32" 
        />
      </a>
    </span>
  );
};

export const AppStoreLink = ({className}) => {
  return (
    <span className={classNames("store-link app-store", className)} >
      <a 
        href="https://apps.apple.com/us/app/id1227336812" 
        target="_blank"
        rel="noreferrer"
      >
        <AppStoreBadge className="app-store" />
      </a>
    </span>
  );
};

export const PlayStoreLink = ({className}) => {
  return (
    <span className={classNames("store-link play-store", className)} >
      <a 
        href="https://play.google.com/store/apps/details?id=net.minimap.minimapweb"
        target="_blank"
        rel="noreferrer"
      >
        <GoogleStoreBadge className="google" />
      </a>
    </span>
  );
};