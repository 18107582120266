import classNames from "classnames";
import { LoMinimapFullColorDark } from "../../resources/icon/colorIcon";
import "./appHeader.scss";
import { useResponsive } from "../../hook/useResponsive";
import { ProductHuntBadge } from "../button/Button";

export const AppHeader = () => {
  const { isMob } = useResponsive();

  return (
    <header className="app-header" >
      <LoMinimapFullColorDark className="header-logo" />
      <span className="header-right" >
        {!isMob &&
          <span className="header-text-button-w" >
            <HeaderTextButton >
              <a href={"https://minimap.net/popup/register?utm_source=minimap&utm_medium=referral&utm_campaign=minimap_landing_page"} target="_blank" rel="noreferrer" >
                Get started
              </a>
            </HeaderTextButton>
          </span>
        }
        <ProductHuntBadge />
      </span>
    </header>
  );
};

const HeaderTextButton = ({className, children, onClick}) => {
  return (
    <span className={classNames("header-text-button", className)} >
      {children}
    </span>
  );
};
