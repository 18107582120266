import './app.scss';
import { Background } from './component/background/Background';
import { AppFooter } from './component/appFooter/AppFooter';
import { AppHeader } from "./component/appHeader/AppHeader";
import { Bottom } from './section/bottom/Bottom';
import { ReviewContent } from './section/component/review/Review';
import { SectionFooter } from './section/footer/SectionFooter';
import { Main } from './section/main/Main';
import { Mid } from './section/mid/Mid';
import { Top } from './section/top/Top';

const App = () => {
  return (
    <div className="app">
      <Background />
      <div className="app-foreground" >
        <AppHeader />
        <Main />
        <Top />
        <Mid />
        <Bottom />
        <ReviewContent />
        <SectionFooter />
        <AppFooter />
      </div>
    </div>
  );
};

export default App;
