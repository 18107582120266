import "./mid.scss";
import { TwoFingers } from "../../resources/emoji";
import { Content, ContentContainer, ContentDescription, ContentTitle, ImageContainer } from "../component/sectionLayout/SectionLayout";
import { MidImage } from "../../resources/image";
import { TextHighlight } from "../../component/text/Text";

const CLASSNAME = "mid";

export const Mid = () => {
  return (
    <Content className={CLASSNAME} >
      <ContentContainer className={CLASSNAME} >
        <TwoFingers className={CLASSNAME} />
        <ContentTitle className={CLASSNAME} >
          {`Multi-Platform\n`}<TextHighlight >Game Account Sync</TextHighlight>
        </ContentTitle>
        <ContentDescription className={CLASSNAME} >
          Seamlessly sync your game accounts across popular platforms, including Steam, PlayStation, Xbox, and Epic Store, so you can track your progress, achievements, and friends across multiple platforms in one convenient place.
        </ContentDescription>
      </ContentContainer>
      <ImageContainer className={CLASSNAME} >
        <MidImage className={CLASSNAME} />
      </ImageContainer>
    </Content>
  );
};