import { ReactComponent as LogoColor } from "./color/LogoColor.svg";
import { ReactComponent as LogoFullColor } from "./color/LogoFullColor.svg";
import { ReactComponent as LogoFullColorDark } from "./color/LogoFullColorDark.svg";

export const LoMinimapColor = (props) => {
  return <LogoColor {...props}/>;
};
export const LoMinimapFullColor = (props) => {
  return <LogoFullColor {...props}/>;
};
export const LoMinimapFullColorDark = (props) => {
  return <LogoFullColorDark {...props}/>;
};
