import classNames from "classnames";
import "./background.scss";

export const Background = () => {
  return (
    <div className="app-background" >
      <BackgroundEllipsis className="el-1" />
      <BackgroundEllipsis className="el-2" />
      <BackgroundEllipsis className="el-3" />
      <BackgroundEllipsis className="el-4" />
      <BackgroundEllipsis className="el-5" />
    </div>
  );
};

export const BackgroundEllipsis = ({className}) => {

  return (
    <span className={classNames("bg-ellipsis", className)} >
      <span className="ellipsis" />
    </span>
  );
};