import "./top.scss";
import { Content, ContentDescription, ContentTitle, ImageContainer, ContentContainer } from "../component/sectionLayout/SectionLayout";
import { TopImage } from "../../resources/image";
import { OneFinger } from "../../resources/emoji";
import { TextHighlight } from "../../component/text/Text";
import { BackgroundEllipsis } from "../../component/background/Background";

const CLASSNAME = "top"

export const Top = () => {
  return (
    <Content className={CLASSNAME} >
      <BackgroundEllipsis className={CLASSNAME} />
      <ContentContainer className={CLASSNAME} >
        <OneFinger className={CLASSNAME} />
        <ContentTitle className={CLASSNAME} >
        <TextHighlight >Personalized</TextHighlight>{` Game\nRecommendations`}
        </ContentTitle>
        <ContentDescription className={CLASSNAME} >
          {`Get personalized game recommendations based on your preferences, game history, and community insights so you never miss out on the perfect game for you.`}
        </ContentDescription>
      </ContentContainer>
      <ImageContainer className={CLASSNAME} >
        <TopImage />
      </ImageContainer>
    </Content>
  );
};