import "./sectionLayout.scss";
import classNames from "classnames";

export const Content = ({children, className}) => {
  return (
    <section className={classNames("content-section", className)} >
      {children}
    </section>
  );
};

export const ContentTitle = ({children, className}) => {
  return (
    <div className={classNames("content-title", className)} >
      {children}
    </div>
  );
};

export const ContentDescription = ({children, className}) => {
  return (
    <div className={classNames("content-description", className)} >
      {children}
    </div>
  );
};

export const ContentContainer = ({children, className}) => {
  return (
    <div className={classNames("content-container", className)} >
      {children}
    </div>
  );
};

export const ImageContainer = ({children, className}) => {

  return (
    <span className={classNames("image-container", className)} >
      {children}
    </span>
  );
};