export const REVEIW_LIST = [
  {
    // 1
    profile: "Frame 5811.png",
    id: "@ninanoo",
    testimonial: "Letterboxd of the gaming world! It's fascinating to see what games other regular players enjoy and the ability to match their preferences with my own is very fresh! It also has an optimized UX for finding worthwhile games to play."
  },
  {
    // 2
    profile: "Frame 5911.png",
    id: "@saewoo",
    testimonial: "I love being able to get a lot of information before starting a game."
  },
  {
    // 3
    profile: "Frame 6011.png",
    id: "@poliecon",
    testimonial: "I've always been looking for an app like this! I love being able to receive game recommendations and use the community features!"
  },
  {
    // 4
    profile: "Frame 6111.png",
    id: "@kookwp1",
    testimonial: "It's so convenient to be able to get game news and leave reviews."
  },
  {
    // 5
    profile: "Frame 6211.png",
    id: "@seonu",
    testimonial: "As someone who loves games, I highly recommend this - I really hope a lot of people use this well-made app."
  },
  {
    // 6
    profile: "Frame 6311.png",
    id: "@arkjun",
    testimonial: "I've been looking for an app where I can keep a complete record of games I've played and want to play, and Minimap was just right. The UI is cute and neat, so it looks good and is easy to use. I'll use it well!"
  },
  {
    // 7
    profile: "Frame 6411.png",
    id: "@sunwooyoung",
    testimonial: "This is the Instagram of games."
  },
  {
    // 8
    profile: "Frame 6511.png",
    id: "@caraway",
    testimonial: "The final destination for gamers."
  },
  {
    // 9
    profile: "Frame 6611.png",
    id: "@killtheps4",
    testimonial: "I love how it syncs with Steam, Epic, PSN, Xbox."
  },
  {
    // 10
    profile: "Frame 6711.png",
    id: "@OOO17",
    testimonial: "You can save games you've played or want to play by platform, see reviews from others, and chat about games in the general forum."
  },
  {
    // 11
    profile: "Frame 6811.png",
    id: "@yellyyelly",
    testimonial: "You can talk about fun games you've played, get information about new games, and the feedback is quick."
  },
  {
    // 12
    profile: "Frame 6911.png",
    id: "@dahee0643",
    testimonial: "First of all, the community on Minimap is very neat. Compared to other communities, people are more careful with their words and it's nice to see content from games I don't usually play since it gathers users who play various types of games."
  },
  {
    // 13
    profile: "Frame 7011.png",
    id: "@llyhjk58",
    testimonial: "I love it! Feels like writing a game diary."
  },
  {
    // 14
    profile: "Frame 7111.png",
    id: "@Aquaflos",
    testimonial: "Wow, it's innovative. If you're looking to find really fun games or want to see people who love games, I highly recommend this app."
  },
];