import "./main.scss";
import { RoundButtonWithDesc } from "../../component/button/Button";
import { useResponsive } from "../../hook/useResponsive";
import { useCallback } from "react";
import { MainIntroMobile, MainIntroWeb } from "../../resources/video";

export const Main = () => {

  const onClick = useCallback(() => {
    window.open("https://minimap.net/popup/register?utm_source=minimap&utm_medium=referral&utm_campaign=minimap_landing_page");
  }, []);

  return (
    <section className="main-section" >
      <header className="content-header main" >
        <div className="title main" >
          {"Your Ultimate\nGaming Companion"}
        </div>
        <div className="desc main" >
          {"Find Your Next Gaming Adventure"}
        </div>
        <RoundButtonWithDesc onClick={onClick} />
      </header>
      <MainContent />
    </section>
  );
};

const MainContent = () => {
  return (
    <div className="content-w" >
      <ContentMedia />
    </div>
  );
};

const ContentMedia = () => {
  const { isMob } = useResponsive();

  return (
    <div className="content media" >
      {isMob 
        ? <MainIntroMobile className="video-player" />
        : <MainIntroWeb className="video-player" />
      } 
    </div>
  );
};