import "./index.scss";
import Top from "./img_01.png";
import Mid from "./img_02.png";
import Bottom from "./img_03.png";
import Footer from "./img_04.png";
import classNames from "classnames";
import AppStoreBadgeImage from "./app_store_badge 1.png";
import GoogleStoreBadgeImage from "./google_play_badge 1.png";

export const TopImage = ({className, ...props}) => {
  return <img className={classNames("content-image", className)} src={Top} alt="" {...props} />;
};

export const MidImage = ({className, ...props}) => {
  return <img className={classNames("content-image", className)} src={Mid} alt="" {...props} />;
};

export const BottomImage = ({className, ...props}) => {
  return <img className={classNames("content-image", className)} src={Bottom} alt="" {...props} />;
};

export const FooterImage = ({className, ...props}) => {
  return <img className={classNames("content-image", className)} src={Footer} alt="" {...props} />;
};

export const AppStoreBadge = ({className, ...props}) => {
  return <img className={classNames("store-link-badge", className)} src={AppStoreBadgeImage} alt="" {...props} />;
};

export const GoogleStoreBadge = ({className, ...props}) => {
  return <img className={classNames("store-link-badge", className)} src={GoogleStoreBadgeImage} alt="" {...props} />;
};