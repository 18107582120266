import { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

export function useResponsive() {
  const mobLayout = useMediaQuery({maxWidth: 600});
  const webLayout = useMediaQuery({minWidth: 1000});
  const tabLayout = useMediaQuery({maxWidth: 1000});

  const isMob = useMemo(() => {
    return isMobile && mobLayout;
  }, [mobLayout]);

  const isTab = useMemo(() => {
    return isMobile && tabLayout;
  }, [tabLayout]);
  
  const isWeb = useMemo(() => {
    if(isMobile) {
      return false;
    };
    return webLayout || tabLayout;
  }, [tabLayout, webLayout]);

  return {
    isMob,
    isTab,
    isWeb
  };
};