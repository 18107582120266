import { useEffect, useState } from "react";
import { getSelectorsByUserAgent, isMobile } from "react-device-detect";

export const useCheckOs = () => {
  const [isIos, setIsIos] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  
  useEffect(() => {
    if(isMobile) {
      const userAgent = navigator.userAgent;
      const {isIOS, isAndroid} = getSelectorsByUserAgent(userAgent);

      if(isIOS) {
        setIsIos(() => true);
      };
      if(isAndroid) {
        setIsAndroid(() => true);
      };
    };
  }, []);

  return {
    isIos,
    isAndroid
  };
};