import { ReactComponent as LogoDiscord } from './solid/Discord.svg';
import { ReactComponent as LogoPlaystation } from './solid/LogoPlaystation.svg';
import { ReactComponent as LogoSteam } from './solid/LogoSteam.svg';
import { ReactComponent as LogoTwitter } from './solid/LogoTwitter.svg';
import { ReactComponent as LogoXbox } from './solid/LogoXbox.svg';
import { ReactComponent as EpicGames } from './solid/EpicGames.svg';
import { ReactComponent as Reddit } from "./solid/Reddit.svg";
import { ReactComponent as Minimap } from "./solid/Minimap.svg";
import { ReactComponent as Check } from "./solid/Check.svg";
import { ReactComponent as CheckCircle } from './solid/CheckCircle.svg';
import { ReactComponent as ThumbsUp } from './solid/ThumbsUp.svg';

export const LoDiscord = ({...props}) => {
	return <LogoDiscord {...props}/>;
};
export const LoEpicGames = ({...props}) => {
	return <EpicGames {...props}/>;
};
export const LoPlaystation = ({...props}) => {
	return <LogoPlaystation {...props}/>;
}
export const LoSteam = ({...props}) => {
	return <LogoSteam {...props}/>;
};
export const LoTwitter = ({...props}) => {
	return <LogoTwitter {...props}/>;
};
export const LoXbox = ({...props}) => {
	return <LogoXbox {...props}/>;
};
export const LoReddit = ({...props}) => {
	return <Reddit {...props}/>;
};
export const LoMinimap = ({...props}) => {
	return <Minimap {...props}/>;
};
export const SoCheck = (props) => {
  return <Check {...props} />
};
export const SoCheckCircle = (props) => {
  return <CheckCircle {...props} />
};
export const SoThumbsUp = (props) => {
  return <ThumbsUp {...props} />
};