import "./appFooter.scss";
import classNames from "classnames";
import { LoTwitter } from "../../resources/icon/solidIcon";

export const LINK_HREF = {
  REDDIT: "https://www.reddit.com/r/minimap/",
  TWITTER: "https://twitter.com/Minimap_global",
  DISCORD: "https://discord.gg/VCMmMMDjEt",
  MINIMAP: "https://minimap.net"
};

export const POLICY_NAME = {
  PRIVACY: "legal",
  TERMS: "term"
};

export const AppFooter = () => {
  return (
    <footer className="app-footer" >
      <span className="app-footer-l">
        <AppLink 
          className="privacy"
          href={undefined}
        > 
          <PolicyLink name={POLICY_NAME.PRIVACY}> 
            Privacy
          </PolicyLink>
        </AppLink>
        <DividerDot />
        <AppLink 
          className="terms"
          href={undefined}
        > 
          <PolicyLink name={POLICY_NAME.TERMS} >
            Terms
          </PolicyLink>
        </AppLink>
        <DividerDot />
        <span className="developer footer-txt-styles" >
          Minimap @ 2023
        </span>
      </span>
      <span className="app-footer-r">
        <AppLink 
          className="twitter"
          href={LINK_HREF.TWITTER}
        > 
          <LoTwitter className="link-icon" />
          Twitter
        </AppLink>
      </span>
    </footer>
  );
};

const AppLink = ({children, className, href}) => {
  return (
    <span className={classNames("app-link-w", "footer-txt-styles", className)} >
      {href 
      ? <a href={href || ""} target="_blank" rel="noreferrer" >
        {children}
        </a>
      : children
      }
    </span>
  );
};

const DividerDot = () => {
  return (
    <span className="divider-dot" >·</span>
  );
};

export const PolicyLink = ({children, name}) => {

  return (
    <a className="policy-link" href={`https://minimap.net/html/${name}.html?lang=en`} target="_blank" rel="noreferrer" >
      {children}
    </a>
  );
};